/* eslint-disable no-use-before-define */
import React, { useContext, useEffect, useState } from "react";
import { useLocation, matchPath, useHistory } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import PropTypes from "prop-types";
import clsx from "clsx";
import PlaylistAddCheckIcon from "@material-ui/icons/PlaylistAddCheck";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

import { FaSignOutAlt, FaUserCircle, FaUser, FaRegEdit } from "react-icons/fa";
import {
  Box,
  Drawer,
  Hidden,
  List,
  ListSubheader,
  makeStyles,
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  Slide,
  ListItem,
  ListItemIcon,
} from "@material-ui/core";
import NavItem from "./NavItem";
import GroupIcon from "@material-ui/icons/Group";
import { HiReceiptRefund } from "react-icons/hi";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import {
  AssignmentTurnedInRounded,
  AttachMoneyRounded,
  BarChartRounded,
  DashboardRounded,
  TocRounded,
  Receipt,
  AccountCircle,
  Assessment,
  QuestionAnswer,
  Category,
  Comment,
  LocalShipping,
  Group,
} from "@material-ui/icons";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { MdOutlineProductionQuantityLimits } from "react-icons/md";
import { FaWarehouse, FaUserAlt } from "react-icons/fa";
import { GiFarmer, GiWheat, GiNewspaper, GiWantedReward } from "react-icons/gi";
import RateReviewIcon from "@material-ui/icons/RateReview";
import Collapse from "@material-ui/core/Collapse";
import ImageIcon from "@material-ui/icons/Image";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import { AuthContext } from "src/context/Auth";

import Logo from "src/component/Logo.js";
const sections = [
  {
    items: [
      {
        title: "Dashboard",
        icon: DashboardRounded,
        href: "/dashboard",
      },
    ],
  },
  {
    items: [
      {
        title: "User Management",
        icon: FaUser,
        href: "",
        items: [
          {
            title: "Farmer Management",
            icon: "",
            href: "/farmer-management",
          },
          {
            title: "Partner Management",
            icon: "",
            href: "/delivery-management",
          },
        ],
      },
    ],
  },
  {
    items: [
      {
        title: "Crop Management",
        icon: GiWheat,
        href: "/crop-management",
      },
    ],
  },

  {
    items: [
      {
        title: "Product Management",
        icon: MdOutlineProductionQuantityLimits,
        href: "/product-management",
      },
    ],
  },
  {
    items: [
      {
        title: "Category Management",
        icon: Category,
        href: "/category-management",
      },
    ],
  },
  {
    items: [
      {
        title: "Order Management",
        icon: AssignmentTurnedInRounded,
        href: "/order-management",
      },
    ],
  },
  {
    items: [
      {
        title: "Refund Management",
        icon: HiReceiptRefund,
        href: "/refund",
      },],
  },
  {
    items: [
      {
        title: "Transpoter Management",
        icon: HiReceiptRefund,
        href: "/transpoter",
      },
    ],
  },
  {
    items: [
      {
        title: "Transaction Management",
        icon: Receipt,
        href: "/transaction-management",
      },
    ],
  },
  {
    items: [
      {
        title: "Query Management",
        icon: QuestionAnswer,
        href: "/query-management",
      },],
  },
  {
    items: [
      {
        title: "Latest Communication",
        icon: QuestionAnswer,
        href: "/chat-history",
      },],
  },
  {
    items: [
      {
        title: "Report Management",
        icon: Assessment,
        href: "/report-management",
      },
    ],
  },
  {
    items: [
      {
        title: "Static Content Management",
        icon: Comment,
        href: "/static-management",
      },
    ],
  },
  {
    items: [
      {
        title: "Banner Management",
        icon: ImageIcon,
        href: "/banner-management",
      },
    ],
  },
  {
    items: [
      {
        title: "News Management",
        icon: GiNewspaper,
        href: "/news-management",
      },
    ],
  },
  {
    items: [
      {
        title: "Warehouse Management",
        icon: FaWarehouse,
        href: "/warehouse-management",
      },
    ],
  },
  {
    items: [
      {
        title: "Collection Point Management",
        icon: PlaylistAddCheckIcon,
        href: "/collection-management",
      },
    ],
  },
  {
    items: [
      {
        title: "Operation Management",
        icon: GiFarmer,
        href: "/operation-area-management",
      },
    ],
  },
  {
    items: [
      {
        title: "Notification Management",
        icon: FaWarehouse,
        href: "/notification-management",
      },
    ],
  },
  {
    items: [
      {
        title: "Rewards & Scheme Management ",
        icon: GiWantedReward,
        href: "/rewards&scheme-management",
      },
    ],
  },
  {
    items: [
      {
        title: "Review Management",
        icon: RateReviewIcon,
        href: "/review",
      },
    ],
  },

  {
    items: [
      {
        title: "SubAdmin Management",
        icon: FaUserAlt,
        href: "/subadmin-management",
      },
    ],
  },

  {
    items: [
      {
        title: "Profile Management",
        icon: AccountCircle,
        href: "/admin-management",
      },
    ],
  },
];

function renderNavItems({ items, pathname, depth = 0 }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, pathname, depth }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({ acc, pathname, item, depth }) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false,
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items,
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        title={item.title}
      />
    );
  }

  return acc;
}

const useStyles = makeStyles((theme) => ({
  mobileDrawer: {
    width: 256,
    background: "rgb(11, 52, 131)",
    backdropFilter: "blur(44px)",
  },
  desktopDrawer: {
    width: 256,
    top: 0,
    height: "100%",
    background: "rgb(11, 52, 131)",
    backdropFilter: "blur(44px)",
    boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px",
  },
  avatar: {
    cursor: "pointer",
    width: 64,
    height: 64,
  },
  socialIcon: {
    cursor: "pointer",
    marginRight: 5,
  },
  logoicon: {
    display: "flex",
    marginTop: "16px",
    alignItems: "center",
    marginLeft: "30px",
  },

  large: {
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
  logoutButton: {
    textAlign: "left",
    display: "flex",
    justifyContent: "flex-start",
    color: "#fff",

    "&:hover": {
      color: "#fff",
      background: "rgb(90 134 255) !important",
      borderRadius: "none !important",
    },
  },
  itemLeaf: {
    display: "flex",
    paddingTop: 0,
    paddingBottom: 0,
    marginTop: "2px",
  },
  buttonLeaf: {
    color: "#f5f7fac9",
    padding: "11px 8px",
    justifyContent: "flex-start",
    textTransform: "none",
    letterSpacing: 0,
    borderLeft: "solid 8px transparent",
    borderRadius: 0,
    fontSize: "13px",
    borderLeft: "3px solid transparent",
    "&:hover": {
      background: "rgb(90 134 255)",
      color: "#fff",
    },

    "&.depth-0": {
      "& $title": {
        fontWeight: theme.typography.fontWeightMedium,
      },
    },
  },
  active: {
    borderLeft: "3px solid #fff",

    fontWeight: theme.typography.fontWeightRegular,
    background: "#15992e",
    "& $title": {
      fontWeight: theme.typography.fontWeightMedium,
    },
    "& $icon": {
      color: "00e0b0",
    },
  },
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const auth = useContext(AuthContext);
  const [colorbg, setColor] = useState();
  const [selectedIndex, setSelectedIndex] = React.useState(1);
  const prmModule = auth.userPermission;

  const [open1, setOpen1] = React.useState(false);

  const handleClick = (event, index) => {
    setOpen1(!open1);
    setSelectedIndex(index);
  };

  const logoutFunc = () => {
    if (auth.userLoggedIn) {
      auth.userLogIn();
      toast.success("Logged Out");
    }
  };

  const [isLogout, setIsLogout] = useState(false);
  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);
  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  const content = (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
      className="example"
    >
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        {/* <Box
          mt={2}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
          }}
        >
          <Avatar
            onClick={() => history.push("/admin-management")}
            className={classes.large}
            src={
              auth?.userData?.profileImage
                ? auth?.userData?.profileImage
                : "images/PlaceholderImg.png"
            }
          />
        </Box> */}
        <Box display={"flex"} justifyContent={"center"}>
          <Link to="/dashboard">
            <Logo />
          </Link>
        </Box>
        <Box pt={1}>
          {auth?.userData?.role!=='ADMIN'&&sections.filter(section=>section.items[0].title==='Dashboard').map((section, i) => (
            <List
              key={`menu${i}`}
              subheader={
                <ListSubheader disableGutters disableSticky>
                  {section.subheader}
                </ListSubheader>
              }
            >
              {renderNavItems({
                items: section.items,
                pathname: location.pathname,
              })}
            </List>
          ))}
          {auth?.userData?.role!=='ADMIN'&&prmModule != null &&
            prmModule.length > 0 &&
            prmModule.map((data, index) => {
              switch (data.moduleName) {
                case "User Management":
                  return (data?.permission.view == true && sections.filter(section=>section.items[0].title===data.moduleName).map((data,index)=>{
                    return (<><List
                      key={`menu${index}`}
                      subheader={
                        <ListSubheader disableGutters disableSticky>
                          {data.subheader}
                        </ListSubheader>
                      }
                    >
                      {renderNavItems({
                        items: data.items,
                        pathname: location.pathname,
                      })}
                    </List></>)
                  }))
                case "Crop Management":
                  return (data?.permission.view == true && sections.filter(section=>section.items[0].title===data.moduleName).map((data,index)=>{
                    return (<><List
                      key={`menu${index}`}
                      subheader={
                        <ListSubheader disableGutters disableSticky>
                          {data.subheader}
                        </ListSubheader>
                      }
                    >
                      {renderNavItems({
                        items: data.items,
                        pathname: location.pathname,
                      })}
                    </List></>)
                  }))
                case "Product Management":
                  return (data?.permission.view == true && sections.filter(section=>section.items[0].title===data.moduleName).map((data,index)=>{
                    return (<><List
                      key={`menu${index}`}
                      subheader={
                        <ListSubheader disableGutters disableSticky>
                          {data.subheader}
                        </ListSubheader>
                      }
                    >
                      {renderNavItems({
                        items: data.items,
                        pathname: location.pathname,
                      })}
                    </List></>)
                  }))
                case "Order Management":
                  return (data?.permission.view == true && sections.filter(section=>section.items[0].title===data.moduleName).map((data,index)=>{
                    return (<><List
                      key={`menu${index}`}
                      subheader={
                        <ListSubheader disableGutters disableSticky>
                          {data.subheader}
                        </ListSubheader>
                      }
                    >
                      {renderNavItems({
                        items: data.items,
                        pathname: location.pathname,
                      })}
                    </List></>)
                  }))
                case "Transaction Management":
                  return (data?.permission.view == true && sections.filter(section=>section.items[0].title===data.moduleName).map((data,index)=>{
                    return (<><List
                      key={`menu${index}`}
                      subheader={
                        <ListSubheader disableGutters disableSticky>
                          {data.subheader}
                        </ListSubheader>
                      }
                    >
                      {renderNavItems({
                        items: data.items,
                        pathname: location.pathname,
                      })}
                    </List></>)
                  }))
                case "Category Management":
                  return (data?.permission.view == true && sections.filter(section=>section.items[0].title===data.moduleName).map((data,index)=>{
                    return (<><List
                      key={`menu${index}`}
                      subheader={
                        <ListSubheader disableGutters disableSticky>
                          {data.subheader}
                        </ListSubheader>
                      }
                    >
                      {renderNavItems({
                        items: data.items,
                        pathname: location.pathname,
                      })}
                    </List></>)
                  }))
                case "Query Management":
                  return (data?.permission.view == true && sections.filter(section=>section.items[0].title===data.moduleName).map((data,index)=>{
                    return (<><List
                      key={`menu${index}`}
                      subheader={
                        <ListSubheader disableGutters disableSticky>
                          {data.subheader}
                        </ListSubheader>
                      }
                    >
                      {renderNavItems({
                        items: data.items,
                        pathname: location.pathname,
                      })}
                    </List></>)
                  }))
                case "Operation Management":
                  return (data?.permission.view == true && sections.filter(section=>section.items[0].title===data.moduleName).map((data,index)=>{
                    return (<><List
                      key={`menu${index}`}
                      subheader={
                        <ListSubheader disableGutters disableSticky>
                          {data.subheader}
                        </ListSubheader>
                      }
                    >
                      {renderNavItems({
                        items: data.items,
                        pathname: location.pathname,
                      })}
                    </List></>)
                  }))
                case "Notification Management":
                  return (data?.permission.view == true && sections.filter(section=>section.items[0].title===data.moduleName).map((data,index)=>{
                    return (<><List
                      key={`menu${index}`}
                      subheader={
                        <ListSubheader disableGutters disableSticky>
                          {data.subheader}
                        </ListSubheader>
                      }
                    >
                      {renderNavItems({
                        items: data.items,
                        pathname: location.pathname,
                      })}
                    </List></>)
                  }))
                case "Rewards & scheme Management":
                  return (data?.permission.view == true && sections.filter(section=>section.items[0].title===data.moduleName).map((data,index)=>{
                    return (<><List
                      key={`menu${index}`}
                      subheader={
                        <ListSubheader disableGutters disableSticky>
                          {data.subheader}
                        </ListSubheader>
                      }
                    >
                      {renderNavItems({
                        items: data.items,
                        pathname: location.pathname,
                      })}
                    </List></>)
                  }))
                case "Static Content Management":
                  return (data?.permission.view == true && sections.filter(section=>section.items[0].title===data.moduleName).map((data,index)=>{
                    return (<><List
                      key={`menu${index}`}
                      subheader={
                        <ListSubheader disableGutters disableSticky>
                          {data.subheader}
                        </ListSubheader>
                      }
                    >
                      {renderNavItems({
                        items: data.items,
                        pathname: location.pathname,
                      })}
                    </List></>)
                  }))
                case "Banner Management":
                  return (data?.permission.view == true && sections.filter(section=>section.items[0].title===data.moduleName).map((data,index)=>{
                    return (<><List
                      key={`menu${index}`}
                      subheader={
                        <ListSubheader disableGutters disableSticky>
                          {data.subheader}
                        </ListSubheader>
                      }
                    >
                      {renderNavItems({
                        items: data.items,
                        pathname: location.pathname,
                      })}
                    </List></>)
                  }))
                case "News Management":
                  return (data?.permission.view == true && sections.filter(section=>section.items[0].title===data.moduleName).map((data,index)=>{
                    return (<><List
                      key={`menu${index}`}
                      subheader={
                        <ListSubheader disableGutters disableSticky>
                          {data.subheader}
                        </ListSubheader>
                      }
                    >
                      {renderNavItems({
                        items: data.items,
                        pathname: location.pathname,
                      })}
                    </List></>)
                  }))
                case "Warehouse Management":
                  return (data?.permission.view == true && sections.filter(section=>section.items[0].title===data.moduleName).map((data,index)=>{
                    return (<><List
                      key={`menu${index}`}
                      subheader={
                        <ListSubheader disableGutters disableSticky>
                          {data.subheader}
                        </ListSubheader>
                      }
                    >
                      {renderNavItems({
                        items: data.items,
                        pathname: location.pathname,
                      })}
                    </List></>)
                  }))
                case "Collection Point Management":
                  return (data?.permission.view == true && sections.filter(section=>section.items[0].title===data.moduleName).map((data,index)=>{
                    return (<><List
                      key={`menu${index}`}
                      subheader={
                        <ListSubheader disableGutters disableSticky>
                          {data.subheader}
                        </ListSubheader>
                      }
                    >
                      {renderNavItems({
                        items: data.items,
                        pathname: location.pathname,
                      })}
                    </List></>)
                  }))
                case "Report Management":
                  return (data?.permission.view == true && sections.filter(section=>section.items[0].title===data.moduleName).map((data,index)=>{
                    return (<><List
                      key={`menu${index}`}
                      subheader={
                        <ListSubheader disableGutters disableSticky>
                          {data.subheader}
                        </ListSubheader>
                      }
                    >
                      {renderNavItems({
                        items: data.items,
                        pathname: location.pathname,
                      })}
                    </List></>)
                  }))
              }
            })}
            {auth?.userData?.role!=='ADMIN'&&sections.filter(section=>section.items[0].title==='Profile Management').map((section, i) => (
            <List
              key={`menu${i}`}
              subheader={
                <ListSubheader disableGutters disableSticky>
                  {section.subheader}
                </ListSubheader>
              }
            >
              {renderNavItems({
                items: section.items,
                pathname: location.pathname,
              })}
            </List>
          ))}
          {auth?.userData?.role==='ADMIN'&&sections.map((section, i) => (
            <List
              key={`menu${i}`}
              subheader={
                <ListSubheader disableGutters disableSticky>
                  {section.subheader}
                </ListSubheader>
              }
            >
              {renderNavItems({
                items: section.items,
                pathname: location.pathname,
              })}
            </List>
          ))}
        </Box>
        <Button
          fullWidth
          onClick={() => setIsLogout(true)}
          className={classes.logoutButton}
        >
          <ExitToAppIcon style={{ marginLeft: "13px" }} />
          &nbsp; &nbsp; Logout
        </Button>
      </PerfectScrollbar>

      {isLogout && (
        <Dialog
          open={isLogout}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => setIsLogout(false)}
        >
          <DialogContent>
            <Box className={classes.dialougTitle}>
              <Typography
                variants="h2"
                style={{ fontSize: "20px", color: "#000" }}
              >
                Are you sure want to logout?
              </Typography>
            </Box>
          </DialogContent>
          <DialogActions
            style={{
              alignContent: "center",
              justifyContent: "center",
            }}
          >
            <Box>
              <Button
                color="primary"
                variant="contained"
                onClick={() => setIsLogout(false)}
              >
                No
              </Button>
              &nbsp;
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  logoutFunc();
                }}
              >
                Yes
              </Button>
            </Box>
          </DialogActions>
        </Dialog>
      )}
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default NavBar;
