import _ from "lodash";
import { colors, createMuiTheme, responsiveFontSizes } from "@material-ui/core";
import typography from "./typography";

const baseOptions = {
  typography,
  overrides: {
    MuiPickersBasePicker: {
      pickerView: {
        display: "flex",
        maxWidth: "325px",
        minWidth: "310px",
        overflowX: "hidden",
        flexDirection: "column",
        justifyContent: "center",
        minHeight: "auto",
      },
    },
    MuiToolbar: {
      gutters: {
        paddingLeft: "0px",
        "@media(min-width:600px)": {
          paddingLeft: "0px",
          paddingRight: "0px",
        },
      },
    },
    MuiSelect: {
      selectMenu: {
        // border: "1px solid #8a8b8b",
        width: "100%",
        borderRadius: "5px",
        fontSize: "13px",
        height: "18px",
        margin: "0",
        // display: "block",
        padding: "10px 12px 7px",
        display: "flex",
        alignItems: "center",
        // border: "1px solid #e09b32 !important",
        // backgroundColor: "#3F3F3F !important",
      },
      select: {
        "&:focus": {
          borderRadius: "5px",
        },
      },
      icon: {
        color: "#000",
      },
    },
    MuiDivider: {
      root: {
        border: "none",
        height: "1px",
        margin: "0",
        flexShrink: "0",
        backgroundColor: "#222",
      },
    },
    MuiInput: {
      root: {
        height: "52px",
        padding: "5px 10px",
      },
      underline: {
        "&::before": {
          left: "0",
          right: "0",
          bottom: "0",
          content: '"\\00a0"',
          position: "absolute",
          transition:
            "border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
          borderBottom: "none",
          pointerEvents: "none",
          display: "none",
          "&:active": {
            borderBottom: "none",
          },
        },
        "&::after": {
          borderBottom: "0px",
        },
      },
    },
    MuiNativeSelect: {
      icon: {
        color: "#777575",
        right: "10px",
      },
      select: {
        paddingLeft: "18px",
        // backgroundColor: "#272727",
      },
    },
    MuiTableCell: {
      root: {
        borderBottom: "0px",
        fontSize: "13px",
        fontWeight: "400 !important",
        fontFamily: "'Poppins', sans-serif",
        padding: "6px 12px",
        "@media (max-width: 768px)": {
          fontSize: "14px",
        },
      },
      head: {
        color: "#f5f5f5",
        background: "#15992e",
        fontSize: "14px",
        // fontWeight: "600 !important",
        // minWidth: "100px",
        padding: "15px 12px",
      },
      body: {
        color: "#222",
      },
    },
    MuiFormControl: {
      marginDense: {
        marginTop: "6px",
      },
      root: {
        borderRadius: "5px",
        width: "100%",
        // height: "38px",
      },
    },
    MuiFormLabel: {
      root: {
        color: "#837F7F",
        fontSize: "18px",
        fontWeight: "600",

        fontFamily: "'Poppins', sans-serif",
        marginTop: "5px",
      },
      colorSecondary: {
        "&.Mui-focused": {
          color: "#222",
        },
      },
    },
    MuiListSubheader: {
      root: {
        color: "#000000",
        fontSize: "22px !important",
        fontWeight: "500 !important",
        lineHeight: "33px !important",
      },
    },
    MuiOutlinedInput: {
      inputAdornedStart: {
        paddingLeft: "12px",
      },
      colorSecondary: {
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
          color: "#222",
          borderColor: "#222",
        },
        "&.Mui-focused": {
          color: "#222",
        },
      },
      inputMarginDense: {
        paddingTop: "18.5px",
        paddingBottom: "18.5px",
      },
      input: {
        padding: "11.5px 14px !important",
      },
    },
    MuiPaper: {
      root: {
        backgroundColor: "1E104D",
        // color: "#fff",
      },
      outlined: {
        padding: "20px",
        width: "100%",
      },
    },
    MuiList: {
      padding: {
        margin: "0px 5px",
      },
    },
    MuiAccordionSummary: {
      root: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        // borderBottom: "1px solid #555555",
        backgroundColor: "#272727",

        padding: "0px 10px 0px 10px",
      },
    },
    MuiIconButton: {
      root: {
        color: "#222",
        padding: "0px !important",
      },
    },
    MuiPopover: {
      root: {
        zIndex: 99999,
      },
      paper: {
        backgroundColor: "#eff5ee",
        color: "#333",
      },
    },
    MuiListItem: {
      root: {
        alignItems: "self-start",
      },
      gutters: {
        paddingLeft: 0,
      },
    },
    MuiCheckbox: {
      root: {
        padding: "4px",
        fontSize: "12px",
        color: "#756f6f",
      },
      colorSecondary: {
        "&.Mui-checked": { color: "#000" },
      },
    },
    MuiFormControlLabel: {
      root: {
        paddingBottom: "0",
      },
    },
    MuiListItemSecondaryAction: {
      root: {
        right: 0,
      },
    },
    MuiDialog: {
      paperScrollPaper: {
        Width: 450,
        maxWidth: "100%",
      },
      paper: {
        overflowY: "unset",
        background: "#f5f5f5",
        border: "1px solid #15992e",
        boxSizing: "border-box",
        // width: '450px',
      },
      paperWidthSm: {
        maxWidth: "500px !important",
      },
    },
    MuiDialogContent: {
      root: {
        overflowY: "none",
      },
    },
    MuiInputBase: {
      root: {
        color: "#fff !important",
      },
      "& :focus": {
        borderColor: "#fff",
      },
      input: {
        fontSize: 14,
        color: "#222",
        height: "1.1876em",
        // padding:"13px !important"
      },
    },
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: "green",
      },
    },
    MuiPickersToolbarText: {
      toolbarTxt: {
        color: "#8F8F8F",
        fontSize: "16px",
        fontWeight: "400",
        margin: "0 15px",
      },
      toolbarBtnSelected: {
        margin: "0px 5px",
        marginTop: "7px",
      },
    },
    MuiPickersCalendarHeader: {
      iconButton: {
        color: "#170C41",
      },
      switchHeader: {
        backgroundColor: "green",
        color: "#fff",
        marginTop: "0px",
        marginBottom: "12px",
      },
      dayLabel: {
        color: "#000",
      },
    },

    // MuiPickersCalendarHeader: {
    //   switchHeader: {
    //     marginTop: "0px",
    //     marginBottom: "12px",
    //     backgroundColor: "#000",
    //   },
    // },
    MuiBackdrop: {
      root: { backgroundColor: "rgba(0, 0, 0, 0.75)" },
    },
    MuiButton: {
      containedSecondary: {
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        borderRadius: "50px",
        color: "#fff",
        fontSize: "13px",
        backgroundColor: "#f30065",
        padding: "5px 19px",
        "&:hover": {
          backgroundColor: "none",
        },
      },

      containedPrimary: {
        backgroundColor: "#15992e",
        borderRadius: "5px",
        fontSize: "12px",
        border: "1px solid #15992e",
        color: "#fff",
        padding: "8px 21px",
        // height: "32px",
        "&:hover": {
          border: "1px solid #15992e",
          color: "#15992e",
          backgroundColor: "#fff",
        },
        "&:active": {
          border: "1px solid #15992e",
          color: "#15992e",
          backgroundColor: "#fff",
        },
      },
      contained: {
        // borderRadius: "50px",
        color: "#f3006",
        fontWeight: 600,
        "&:hover": {
          border: "1px solid #fff",
          color: "#fff",
          backgroundColor: "#170C41",
        },
        "&.active": {
          border: "1px solid #15992e",
          color: "#15992e",
          backgroundColor: "#fff",
        },
      },
      outlined: {
        border: "1px solid #15992e",
        padding: "5px 15px",
      },
      outlinedPrimary: {
        borderRadius: "5px",
        color: "#222",
        fontWeight: 400,
        padding: "7px 21px",
        border: "1px solid #15992e",
        "&:hover": {
          // backgroundColor: "#713FFF",
          // border: "2px solid #E19C32",
          color: "#15992e",
        },
      },
      outlinedSizeSmall: {
        padding: "6px 23px",
        fontSize: "16px",
        lineHeight: " 24px",
      },
    },
    MuiDrawer: {
      paperAnchorDockedLeft: {
        borderRight: "0",
      },
    },

    MuiRadio: {
      root: {
        color: "grey",
      },
    },

    MuiTypography: {
      subtitle1: {
        color: "#0000006b",
        fontSize: "14px",
        fontWeight: 500,
        lineHeight: " 16px",
        colorSecondary: {
          color: "#8d8989",
        },
      },
      colorTextSecondary: {
        color: "black",
      },
    },
    MuiTable: {
      root: {
        borderCollapse: "none !important",
        width: "100%",
        "@media(max-width:1225px)": {
          width: "1225px",
        },
      },
      MuiOutlinedInput: {
        root: {
          height: "40px !important",
        },
        notchedOutline: {
          border: "none",
        },
      },
    },
    MuiTableContainer: {
      root: {
        overflowX: "auto",
      },
    },
    MuiMenu: {
      paper: {
        maxHeight: "323px !important"
      }

    }
  },
};

const themesOptions = {
  typography: {
    fontWeight: 400,
    fontFamily: "'Poppins', sans-serif",
  },
  palette: {
    type: "light",
    action: {
      primary: "#20509e",
    },
    background: {
      default: "#FBFBFD",
      dark: "#f3f7f9",
      paper: colors.common.white,
    },
    primary: {
      main: "#898989",
      dark: "#de0d0d",
      light: "#de0d0d",
    },
    secondary: {
      main: "#fff",
    },
    warning: {
      main: "#ffae33",
      dark: "#ffae33",
      light: "#fff1dc",
    },
    success: {
      main: "#54e18c",
      dark: "#54e18c",
      light: "#e2faec",
    },
    error: {
      main: "#ff7d68",
      dark: "#ff7d68",
      light: "#ffe9e6",
    },
    text: {
      primary: "#222",
      secondary: "#f5f5f5",
    },
    common: {
      black: "#222222",
    },
  },
};

export const createTheme = (config = {}) => {
  let theme = createMuiTheme(_.merge({}, baseOptions, themesOptions));

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
};
