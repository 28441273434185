import React, { createContext, useEffect, useState } from "react";
import axios from "axios";
import { FaWindowRestore } from "react-icons/fa";
import { getAPIdata } from "src/utils";
import Apiconfigs from "src/ApiConfig/ApiConfig";
export const AuthContext = createContext();

const setSession = (accessToken) => {
  if (accessToken) {
    window.sessionStorage.setItem("AccessToken", accessToken);
    // axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    window.sessionStorage.removeItem("AccessToken");
    // delete axios.defaults.headers.common.Authorization;
  }
};

function checkLogin() {
  const accessToken = window.sessionStorage.getItem("AccessToken");
  return accessToken ? true : false;
}

export default function AuthProvider(props) {
  const [isLogin, setIsLogin] = useState(checkLogin());
  // const [isLogin, setIsLogin] = useState(false);
  const [endTime, setEndtime] = useState();
  const [timeLeft, setTimeLeft] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [userPermission,setUserPermission]=useState([]);
  const [userData, setUserData] = useState();
  const token = window.sessionStorage.getItem("AccessToken");
  let data = {
    userLoggedIn: isLogin,
    userData,
    userPermission,
    getProfileHandler: (token) => getProfileHandler(token),
    userLogIn: (type, data) => {
      setSession(data);
      setIsLogin(type);
    },
  };
  const getProfileHandler = async (token) => {
    try {
      let res;
      res = await getAPIdata({
        params: {
          userId: token
        },
        endPoint: Apiconfigs.myAccountDetails,
      });
      if (res) {
        // let initData = res.pop();
        setUserData(res)
        setUserPermission(JSON.parse(res?.permission))
      }
    } catch (error) {
      setIsLogin(false);
      setIsLoading(false);
      console.log("ERROR", error);
    }
  };

  useEffect(() => {
    if (window.sessionStorage.getItem("AccessToken")) {
      getProfileHandler(window.sessionStorage.getItem("AccessToken"))


    } else {
      setIsLogin(false);
      setUserData();
    }
  }, [token]);

  return (
    <AuthContext.Provider value={data}>{props.children}</AuthContext.Provider>
  );
}
