import { Contract } from "@ethersproject/contracts";
import moment from "moment";
import Apiconfigs from "src/ApiConfig/ApiConfig";
import axios from "axios";
import { toast } from "react-toastify";
import { sentenceCase } from 'change-case';

export const googlePlaceKey="AIzaSyCzU4XQ6D43-mEnHWZ5l3vobePxE6p2GRw"
export function sortAddress(add) {
  if (add.length > 6) {
    const sortAdd = `${add?.slice(0, 6)}...${add?.slice(add.length - 4)}`;
    return sortAdd;
  } else {
    return add;
  }
}

export function ShortDescritionFunction(add) {
  if (add.length > 20) {
    const sortAdd = `${add?.slice(0, 20)}...`;
    return sortAdd;
  } else {
    return add;
  }
}
export function getSigner(library, account) {
  return library.getSigner(account).connectUnchecked();
}

export function getProviderOrSigner(library, account) {
  return account ? getSigner(library, account) : library;
}

export function getContract(address, ABI, library, account) {
  return new Contract(address, ABI, getProviderOrSigner(library, account));
}

export const getUnixTime = (val) => {
  return moment(val).unix() * 1000;
};

export const convertDateTime = (val) => {
  var tempDate = new Date(val);
  const toDateFormat = moment(tempDate).format("DD-MMM-yyyy hh:mm a");
  return toDateFormat;
};

export const getDayFromDate = (val) => {
  var tempDate = new Date(val);
  const options = { weekday: 'long' }; // Specify 'long' for the full day name
  const dayName = tempDate.toLocaleDateString(undefined, options);
  return dayName;
};

export const handleFileUpload = async (event) => {
  if (event.target.files.length > 0) {
    const fileType = event.target.files[0].name.substring(
      event.target.files[0].name.lastIndexOf(".") + 1
    );
    if (
      fileType === "png" ||
      fileType === "jpeg" ||
      fileType === "jpg" ||
      fileType === "gif" ||
      fileType === "webp"
    ) {
      try {
        let res, formdata;
        formdata = new FormData();

        formdata.append("file", event.target.files[0]);

        res = await axios({
          url: Apiconfigs.fileUpload,
          method: "POST",
          data: formdata,
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
          .then((res) => {
            if (res?.status === 200) {

              return res.data.data;
            }
          })
          .catch((error) => {
            toast.error(error.response.message);
            return false;
          });
        return res;
      } catch (error) {
        return false;
      }
    } else {
      window.alert(
        "Please upload file having extensions .png .jpeg .jpg .webp"
      );
      return false;
    }
  }
};

export const handleMultipleFileUpload = async (event) => {
  try {
    let res, formdata;
    formdata = new FormData();

    event.forEach((file) => {
      formdata.append("files", file);
    });

    res = await axios({
      url: Apiconfigs.uploadFileMultiple,
      method: "POST",
      data: formdata,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then((res) => {
        if (res?.status === 200) {

          return res.data.data;
        }
      })
      .catch((error) => {
        toast.error(error.response.message);
        return false;
      });
    return res;
  } catch (error) {

    return false;
  }
};

export const getAPIdataSearch = async ({ params, data, endPoint, headers, cancelTokenSourceRef }) => {

  try {
    let res;
    let cancelTokenSource = axios.CancelToken.source();
    cancelTokenSourceRef.current = cancelTokenSource;
    res = await axios({
      url: endPoint,
      method: "GET",
      params: params,
      headers: headers,
      cancelToken: cancelTokenSource.token,
    })
      .then((res) => {
        if (res?.data?.status === 200) {
          // toast.success(res?.data?.message)
          return res?.data?.data;
        }
        if (res?.data?.status === 400) {
          toast.error(res?.data?.message);
          return false;
        }
        if (res?.data?.status === 401) {
          toast.error(res?.data?.message);
          return false;
        }
        if (res?.data?.status === 205) {
          // toast.error(res?.data?.message)
          return false;
        }
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          // Request was canceled, so no need to handle the error
          return;
        }
        toast.error(error.response.data.error);
        return false;
      });
    return res;
  } catch (error) {
    return false;
  }
};

export const getAPIdata = async ({ params, data, endPoint, headers }) => {

  try {
    let res;

    res = await axios({
      url: endPoint,
      method: "GET",
      params: params,
      headers: headers,
    })
      .then((res) => {
        if (res?.data?.status === 200) {
          // toast.success(res?.data?.message)
          return res?.data?.data;
        }
        if (res?.data?.status === 400) {
          toast.error(res?.data?.message);
          return false;
        }
        if (res?.data?.status === 401) {
          toast.error(res?.data?.message);
          return false;
        }
        if (res?.data?.status === 205) {
          // toast.error(res?.data?.message)
          return false;
        }
      })
      .catch((error) => {

        toast.error(error.response.data.error);
        return false;
      });
    return res;
  } catch (error) {
    return false;
  }
};

export const putAPIdata = async ({ params, data, endPoint, headers }) => {

  try {
    let res;

    res = await axios({
      url: endPoint,
      method: "PUT",
      params: params,
      data: data,
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (res?.data?.status === 200) {
          toast.success(res?.data?.message);
          return res?.data?.data;
        }
        if (res?.data?.status === 400) {
          toast.error(res?.data?.message);
          return false;
        }
        if (res?.data?.status === 401) {
          toast.error(res?.data?.message);
          return false;
        }
      })
      .catch((error) => {
        toast.error(error.response.message);
        return false;
      });
    return res;
  } catch (error) {
    return false;
  }
};

export const postAPIdata = async ({ params, data, endPoint, headers }) => {

  try {
    let res;

    res = await axios({
      url: endPoint,
      method: "POST",
      params: params,
      data: data,
      headers: headers,
    })
      .then((res) => {
        if (res?.data?.status === 200) {
          if (res?.data?.data) {
            // toast.success(res?.data?.message)
            return res?.data?.data;
          } else {
            // toast.success(res?.data?.message)
            return res?.data;
          }
        }
        if (res?.data?.status === 201) {
          if (res?.data?.data) {
            // toast.success(res?.data?.message)
            return res?.data?.data;
          } else {
            // toast.success(res?.data?.message)
            return res?.data;
          }
        }
        if (res?.data?.status === 400) {
          toast.error(sentenceCase(res?.data?.message));
          return false;
        }
        if (res?.data?.status === 401) {
          toast.error(sentenceCase(res?.data?.message));
          return false;
        }
        if (res?.data?.status === 205) {
          if (res?.data?.message != 'CROP_DETAILS__NOT_FOUND')
            toast.error(sentenceCase(res?.data?.message))
          return false;
        }
      })
      .catch((error) => {
        toast.error(sentenceCase(error.response.data.message));
        if (error.response.data.status === 401) {
          toast.error(sentenceCase(error.response.data.error));
          return false;
        }
        return false;
      });
    return res;
  } catch (error) {
    return false;
  }
};

export const deleteAPIdata = async ({ params, data, endPoint, headers }) => {

  try {
    let res;

    res = await axios({
      url: endPoint,
      method: "DELETE",
      params: params,
      data: data,
      headers: headers,
    })
      .then((res) => {
        if (res?.data?.status === 200) {
          toast.success(res?.data?.message);
          return res?.data?.data;
        }
        if (res?.data?.status === 400) {
          toast.error(res?.data?.message);
          return false;
        }
        if (res?.data?.status === 401) {
          toast.error(res?.data?.message);
          return false;
        }
      })
      .catch((error) => {
        toast.error(error.response.message);
        return false;
      });
    return res;
  } catch (error) {
    return false;
  }
};
