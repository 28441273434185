export const baseurl = "https://java-farm.mobiloitte.org"; // staging
export const socketNotification = "https://socket-farm.mobiloitte.io/chat"; // staging
// export const baseurl = "http://172.16.1.247:6023"; //local
// export const socketNotification = "ws://172.16.1.247:9092/chat"; //local
let product = `${baseurl}/product`;
let account = `${baseurl}/account`;
let notification = `${baseurl}/notification`;
let staticContent = `${baseurl}/static`;
let zoho = `${baseurl}/zoho`;

const Apiconfigs = {
  //Super Admin

  dashboard: `${account}/dashboard-api`,
  login: `${account}/admin-login`,
  myAccountDetails: `${account}/my-account-details`,
  adminProfile: `${account}/admin-profile`,
  changePassword: `${account}/change-password`,
  verifyEmailCodeWeb: `${account}/verify-email-code-web`,
  resetPasswordMobileApp: `${account}/reset-password-mobile-app`,
  sendOtpCode: `${account}/send-otp-code`,
  forgotPassword: `${account}/forgot-password`,
  uploadFileMultiple: `${account}/upload-file-multiple`,
  //image file upload api
  fileUpload: `${product}/upload-file-New`,
  //crops api
  getAllCropList: `${product}/crop-details-search-filter`,
  addCropListingDetails: `${product}/add-crop-listing-details`,
  updateCropListingDetails: `${product}/update-crop-listing-details`,
  deleteCropListingDetails: `${product}/delete-crop-listing-details`,
  viewCropdetailsByUserId: `${product}/view-Crop-details-By-Id`,
  viewCropdetailsById: `${product}/view-Crop-details-By-crop-Id`,
  completeDelivery: `${product}/complete-delevery-order`,
  cancleOrder: `${product}/cancel-return-order`,

  //scheme management
  schemelist: `${product}/serach-and-filter-scheme`,
  AddSheme: `${product}/add-scheme`,
  schemereward: `${product}/serach-and-filter-reward`,
  viewscheme: `${product}/view-scheme-by-id`,
  updatescheme: `${product}/update-scheme`,
  deletescheme: `${product}/delete-scheme`,
  //product api
  getAllproductList: `${product}/get-search-filter-by-name`,
  productUpload: `${product}/file-upload`,
  viewProduct: `${product}/view-Product-by-Id`,
  updateProduct: `${product}/update-product`,
  addProduct: `${product}/add-product`,
  viewImagebyItemId: `${product}/view-Image-by-ItemId`,
  //farmers api
  addFarmerProfileDetail: `${account}/add-farmer-profile-detail`,
  getFarmerList: `${account}/farmer-details-search-filter`,
  farmerUpload: `${account}/bulk-create-farmer`,
  viewFarmerDetailsById: `${account}/view-Farmer-Details-By-Id`,
  updateFarmerProfileDetails: `${account}/update-farmer-profile-details`,
  blockFarmerProfile: `${account}/block-farmer-profile`,
  unblockFarmerProfile: `${account}/unblock-farmer-profile`,
  deleteFarmerProfile: `${account}/delete-farmer-profile-details`,
  //deliverpartners api
  addProfileDetailofDeliveryPartner: `${account}/add-profile-detail-of-delivery-partner`,
  updateDeliveryPartnerDetails: `${account}/update-delivery-partner-details`,
  partnerUpload: `${account}/bulk-create-partner`,
  accptRejectPartner: `${account}/accept-and-reject-partner-by-admin`,
  farmerByPartner: `${account}/farmer-details-by-partner-id`,
  getAllPartnerListPendingApproved: `${account}/pending-delivery-partner-details-search-filter`,
  getAllPartnerList: `${account}/delivery-partner-details-search-filter`,
  viewdeliverypartnerDetailsById: `${account}/view-deliverypartner-Details-By-Id`,
  //Category api
  getAllCategoryList: `${product}/category-list-details-search-filter`,
  addCategory: `${product}/add-Category`,
  blockCategory: `${product}/block-category`,
  deleteCategory: `${product}/delete-category`,
  unBlockCategory: `${product}/unblock-category`,
  editCategory: `${product}/edit-category`,
  viewCategoryById: `${product}/view-category-By-Id`,
  //order api
  getAllorderlist: `${product}/get-All-orderlist-search-filter`,
  viewOrder: `${product}/view-order-By-Id`,
  getOrderHistoryByuserId: `${product}/get-orderHistory-By-userId`,
  //transporter api
  transporterDetailsSearchFilter: `${account}/transporter-details-search-filter`,
  addTransporterDetails: `${account}/add-transporter-details`,
  editTransporterDetails: `${account}/update-brand`,
  viewTransporterDetailsById: `${account}/view-transporter-Details-By-Id`,
  //notification api
  notificationManagementDetailsSearchFilter: `${notification}/list-of-notification`,
  addNotificationManagementDetails: `${notification}/notification/token`,
  // updateNotificationManagementdetails: `${notification}/update-NotificationManagement-details`,
  // viewallNotificationManagementmassge: `${notification}/view-all-NotificationManagement-massge`,
  //static content api
  addStaticContentData: `${staticContent}/add-static-content-data`,
  getAllStaticContentData: `${staticContent}/get-all-static-content-data`,
  updateStaticContentData: `${staticContent}/update-static-content-data`,
  viewContentDataById: `${staticContent}/view-content-data-By-Id`,
  //banner content api
  addBanner: `${staticContent}/add-banner`,
  updateBanner: `${staticContent}/update-banner-details`,
  bannerDetailsSearchFilter: `${staticContent}/banner-details-search-filter`,
  deleteBannerDetails: `${staticContent}/delete-banner-details`,
  viewBannerDetailsById: `${staticContent}/view-Banner-Details-By-Id`,
  //news content api
  addNews: `${staticContent}/add-news`,
  blockNewsDetails: `${staticContent}/block-news-details`,
  deleteNewsDetails: `${staticContent}/delete-news-details`,
  viewNewsDetailsById: `${staticContent}/view-news-Details-By-Id`,
  newsDetailsSearchFilter: `${staticContent}/news-details-search-filter`,
  updateNewsDetails: `${staticContent}/update-news-details`,
  unblockNewsDetails: `${staticContent}/unblock-news-details`,
  //warehouse content api
  addWarehouseDetails: `${product}/add-warehouse-details`,
  blockWarehouseProfile: `${product}/block-unblock-warehouse-profile`,
  unblockWarehouseProfile: `${product}/block-unblock-warehouse-profile`,
  warehousManagementDetailsSearchFilter: `${product}/WarehousManagement-details-search-filter`,
  viewWarehouseDetailsById: `${product}/view-warehouse-Details-By-Id`,
  updateWarehouseDetails: `${product}/update-warehouse-details`,
  getAllactiveWarehouseDetails: `${product}/get-all-active-warehouse-details`,
  //sub admin api
  addsubAdminDetails: `${account}/add-subAdmin-details`,
  blockSubAdminProfile: `${account}/block-subAdmin-profile`,
  unblockSubAdminProfile: `${account}/unblock-subAdmin-profile`,
  updateSubAdminDetails: `${account}/update-subAdmin-details`,
  viewSubAdminDetailsById: `${account}/view-subAdmin-Details-By-Id`,
  getAllsubAdminlist: `${account}/get-All-subAdmin-list`,
  //collection point api
  blockCollectionPointDetails: `${product}/block-unblock-collection-point-details`,
  addCollectionPoint: `${product}/add-collection-point`,
  getAllCollectionPointDetails: `${product}/collection-point-details-search-filter`,
  updateCollectionPointDetail: `${product}/update-collection-point-details`,
  viewCollectionPointDetailsById: `${product}/view-collection-point-Details-By-Id`,

  //operations api
  getAllOperationList: `${account}/get-all-operation-list`,
  blockOperationDetails: `${account}/block-operation-details`,
  unblockOperationDetails: `${account}/active-operation-details`,
  //country details
  getStateCountryWise: `${account}/get-state-country-wise`,
  getBlockDistrictWise: `${account}/get-block-district-wise`,
  getDistrictStateWise: `${account}/get-district-state-wise`,
  getCityDistrictWise: `${account}/get-city-district-wise`,

  getAllOrderListForAdmin: `${product}/get-all-orderlist-for-admin`,
  getAlltransacionsearchfilter: `${product}/get-All-transacion-search-filter`,

  //getReports
  apiGetReportList: `${product}/api-get-report-list`,

  //transactions
  getAlltransactionsearchfilter: `${product}/get-All-transacion-search-filter`,
  viewtransacionBytransacionId: `${product}/view-transacion-By-transacionId`,

  //review
  getAllRatingsearchfilter: `${product}/get-All-Rating-search-filter`,
  viewRatingById: `${product}/view-Rating-By-Id`,

  //dashboard
  totalGraphEarning: `${product}/total-Graph-earning`,

  //refund
  searchFilterRefund: `${product}/search-filter-refund`,
  viewRefund: `${product}/view-refund`,

  //query
  querySearchFilter: `${account}/search-filter`,
  forwardQuery: `${account}/forward-query`,
  deleteQuery: `${account}/delete-query`,
  viewQuery: `${account}/view-query`,
  solveQuery: `${account}/solve-query`,

  //zoho get images
  getImages: `${zoho}/product-management/get-document-image`


};

export const socketConfig = {
  connectSocket: `connect`,
  getOnline: `onlineUser`,
  getOffline: `offlineUser`,
  checkOnline: `checkOnlineUser`,
  StartChat: `oneToOneChat`,
  chatHistory: `chatHistory`,
  chatList: `chatList`,
  disconnect: `disconnect`,
};

export default Apiconfigs;
