import React, { lazy } from "react";
import { Redirect } from "react-router-dom";
import HomeLayout from "src/layouts/HomeLayout";
import DashboardLayout from "src/layouts/DashboardLayout";

export const routes = [
  {
    exact: true,
    path: "/",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Home/auth/Login")),
  },

  {
    exact: true,
    path: "/forget-password",
    layout: HomeLayout,

    component: lazy(() =>
      import("src/views/pages/Home/auth/forget-password/index")
    ),
  },

  {
    exact: true,
    path: "/reset-password",
    layout: HomeLayout,

    component: lazy(() =>
      import("src/views/pages/Home/auth/reset-password/index")
    ),
  },
  {
    exact: true,
    path: "/verify-otp",
    layout: HomeLayout,

    component: lazy(() =>
      import("src/views/pages/Home/auth/Verify-Otp/VerifyOtp")
    ),
  },
  {
    exact: true,
    path: "/dashboard",
    layout: DashboardLayout,
    guard: true,
    component: lazy(() => import("src/views/pages/Dashboard/Dashboard")),
  },
  {
    exact: true,
    path: "/farmer-management",
    layout: DashboardLayout,
    guard: true,
    component: lazy(() =>
      import("src/views/pages/Dashboard/FarmerMangement/FarmerManagement")
    ),
  },
  {
    exact: true,
    path: "/warehouse-management",
    layout: DashboardLayout,
    guard: true,
    component: lazy(() =>
      import("src/views/pages/Dashboard/WarehouseManagement/WareHouseListing")
    ),
  },
  {
    exact: true,
    path: "/operation-area-management",
    layout: DashboardLayout,
    guard: true,
    component: lazy(() =>
      import(
        "src/views/pages/Dashboard/OperationManagement/OperationManagement"
      )
    ),
  },
  {
    exact: true,
    path: "/collection-management",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/CollectionManagement/CollectionListing")
    ),
  },

  {
    exact: true,
    path: "/rewards&scheme-management",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/Rewards/SchemeManagement")
    ),
  },
  {
    exact: true,
    path: "/chat-history",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/component/chat")),
  },
  {
    exact: true,
    guard: true,
    path: "/refund",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/FarmerMangement/Refund")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/review",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/FarmerMangement/Review")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/Addtransporter",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/Transporter/AddTransporter")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/transpoter",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/Transporter/Transpoter")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/rewardsView",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/Rewards/RewardsViewCom")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/delivery-management",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/DelevryManagement/DelevryManagement")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/partner-request-list",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/DelevryManagement/PartnerRequestList")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/order-management-particular",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/DelevryManagement/OrderParticular")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/crop-management",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/CropManagement/CropManagement")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/product-management",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/ProductManagment/ProductManagement")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/order-management",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/Order-Management/orderManagement")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/transaction-management",
    layout: DashboardLayout,
    component: lazy(() =>
      import(
        "src/views/pages/Dashboard/TransactionManagement/TransactionManagement"
      )
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/category-management",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/CategoryManagement/CategoryMangement")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/query-management",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/QueryManagement/QueryManagement")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/forword",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/QueryManagement/AfterForwordQuery")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/View-QueryManage",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/QueryManagement/ViewQueryAfterResolve")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/report-management",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/ReportManagment/Report")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/static-management",
    layout: DashboardLayout,
    component: lazy(() =>
      import(
        "src/views/pages/Dashboard/StaticContentManagement/StaticContentManagement"
      )
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/banner-management",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/BannerManagement/BannerManagement")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/news-management",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/NewsManagement/NewsManagement")
    ),
  },
  // {
  //   exact: true,
  //   guard: false,
  //   path: "/add-news",
  //   // layout: DashboardLayout,
  //   component: lazy(() =>
  //     import("src/views/pages/Dashboard/NewsManagement/AddNews1")
  //   ),
  // },
  {
    exact: true,
    guard: true,
    path: "/notification-management",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/NotificationManagement/NotificationManagement")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/admin-management",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/Profile/AdminProfile")
    ),
  },

  {
    exact: true,
    path: "/view-farmer",
    guard: true,
    layout: HomeLayout,

    component: lazy(() =>
      import("src/views/pages/Dashboard/FarmerMangement/ViewFarmerData")
    ),
  },
  {
    exact: true,
    path: "/crop-view",
    guard: true,
    layout: HomeLayout,

    component: lazy(() =>
      import("src/views/pages/Dashboard/CropManagement/ViewCrop")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/veiw-qurey-resolve",
    layout: DashboardLayout,

    component: lazy(() =>
      import("src/views/pages/Dashboard/QueryManagement/ViewQueryAfterResolve")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/order-view",
    layout: HomeLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/FarmerMangement/OrderView")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/subadmin-management",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/SubAdminManagement/SubAdminManagement")
    ),
  },
  {
    exact: true,
    path: "/404",
    component: lazy(() => import("src/views/errors/NotFound")),
  },
  {
    component: () => <Redirect to="/404" />,
  },
];
